






































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiCrontabAdd,
    apiCrontabEdit,
    apiCrontabDetail,
    apiCrontabExpression
} from '@/api/setting/system_maintain/system_maintain'
import { PageMode } from '@/utils/type'

@Component
export default class HelpEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面: add-添加角色 edit-编辑角色

    // 表
    lists: Array<object> = []

    // 表单数据
    form: any = {
        name: '', //	是	string	名称
        type: 1, //	是	integer	类型
        command: '', //	是	string	命令
        expression: '', //	是	string	运行规则
        status: 1, //	是	integer	状态
        remark: '', //	否	string	备注
        params: '' //	否	string	参数
    }

    // 表单校验
    rules: object = {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        command: [{ required: true, message: '请输入命令', trigger: 'blur' }],
        params: [{ required: true, message: '请输入参数', trigger: 'blur' }],
        expression: [{ required: true, message: '请输入规则', trigger: 'blur' }]
    }

    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): void => {
            if (!valid) {
                return
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleNoticeAdd()
                case PageMode.EDIT:
                    return this.handleNoticeEdit()
            }
        })
    }

    blur() {
        if (this.form.expression != '') {
            this.getExpressionFun()
        }
    }

    getExpressionFun() {
        apiCrontabExpression({
            expression: this.form.expression
        }).then(res => {
            this.lists = res
        })
    }

    // 添加定时任务
    handleNoticeAdd() {
        const params = this.form

        apiCrontabAdd(params)
            .then(() => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('添加失败!')
            })
    }

    // 编辑帮助文章
    handleNoticeEdit() {
        delete this.form.status_desc
        delete this.form.type_desc
        const params = this.form
        apiCrontabEdit(params)
            .then(() => {
                // this.$message.success("修改成功!");
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('修改失败!')
            })
    }

    // 初始化表单数据
    initFormDataForNoticeEdit() {
        apiCrontabDetail({
            id: this.form.id
        })
            .then((res: any) => {
                Object.keys(res).map(item => {
                    this.$set(this.form, item, res[item])
                })
                this.getExpressionFun()
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        if (query.mode) {
            this.mode = query.mode
        }

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            this.initFormDataForNoticeEdit()
        }
    }

    /** E Life Cycle **/
}
